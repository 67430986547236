const secrets = {
    bingKey: 'AmHj4RMhOs0a4O3uk3m144GJik4Y-SIuy1bheg8rGeLFQA3N-Gqs0fJPLRFnuJSd',
    thunderforestKey: 'cf99c28b955f493eb805df0e9f4c7c30',
    sentryDSN: 'https://11ff06bc986e46aaa40d5ea406a14aaa@sentry.io/144904',
    mapillary4: 'MLY|5134371116619832|9a5949fa0d3bba986c5202c54c884f53',
    flickr: '1eff3f57e8aa5886a70a0a71c9a88217',
    google: 'AIzaSyBmRhm_v64YGugpGCYwj3HbndF10XJZw3o',
    mapyCz: 'eyJpIjozNDksImMiOjE2NzMzMzgyNzh9.6O3cIE83j5vY61dZJETwmBCB2jepG1PUqISKHwzwL3Y',
};

export default secrets;
